<template>
    <div class="products">
      <div class="page-header">
        <h1>{{ $t('Member Management')}} > {{ $t('Member News') }}</h1>
      </div>
      <div class="page-content">
        <div class="content-header">
          <h2>{{ $t('Member News') }}</h2>
          <el-button class="action-button" type="primary">{{ $t('Create Member News') }}</el-button>
        </div>
  
        <div class="inventory-list">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">{{ $t('Title') }}</th>
                <th scope="col">{{ $t('Created Date') }}</th>
                <th scope="col">{{ $t('Actions') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>「夏日新品登場！清涼美味特調等你來嚐！」</td>
                <td>2024-08-09 15:00:00</td>
                <td>
                 <a class="action-link">
                  {{ $t('View') }}
                 </a>
                </td>
              </tr>
              <tr>
                <td>「端午節特別套餐上市，限時優惠快來享受！」</td>
                <td>2024-07-09 13:57:39</td>
                <td>
                 <a class="action-link">
                  {{ $t('View') }}
                 </a>
                </td>
              </tr>
              <tr>
                <td>「暑期限定料理上線，與家人共度歡樂時光！」</td>
                <td>2024-06-09 12:45:21</td>
                <td>
                 <a class="action-link">
                  {{ $t('View') }}
                 </a>
                </td>
              </tr>
              <tr>
                <td>「春天狂歡祭，加入我們的派對夜晚！」</td>
                <td>2024-05-09 15:57:39</td>
                <td>
                 <a class="action-link">
                  {{ $t('View') }}
                 </a>
                </td>
              </tr>
              <tr>
                <td>「春天將至！全新優惠」</td>
                <td>2024-04-09 15:57:39</td>
                <td>
                 <a class="action-link">
                  {{ $t('View') }}
                 </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  import ItemFilter from '@/components/Filter.vue';
  import Common from '@/lib/common';
  import Member from '@/lib/member';
  
  export default {
    name: 'Members',
    components: {
      ItemFilter,
    },
    mounted(){
      this.loadAllMembers();
    },
    methods:{
      async loadAllMembers(){
        try{
          const loginInfo = Common.getLoginInfo();
          const members = await Member.loadAllMembers(this.apiUrl, loginInfo);
          this.members = members;
        }catch(err){
          console.log(err);
        }
      },
    },
    data(){
      return {
        members: [],
        orignalMembers: [],
      }
    },
    computed: mapState({
     apiUrl: state => state.apiUrl,
   }),
  }
  </script>
  
  <style scoped lang="scss">
  .content-header{
    display: flex;
    h2{
      flex: 1;
    }
    .action-button{
      margin-right: 20px;
    }
  }
  </style>
  